import { animate, animateChild, group, query, style, transition } from '@angular/animations';

import {
    EASING_ENTERING,
    EASING_LEAVING,
    VELOCITY_ENTERING,
    VELOCITY_LEAVING
} from './animation.constants';

export const routeSlideDownAnimation = [
    transition('* => SlideDown', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                opacity: 1,
                width: '100%'
            })
        ]),
        query(':enter', [style({ opacity: 0, transform: 'translateY(-10%)' })]),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(
                ':leave',
                [animate(`${VELOCITY_LEAVING} ${EASING_LEAVING}`, style({ opacity: 0 }))],
                { optional: true }
            ),
            query(':enter', [
                animate(
                    `${VELOCITY_ENTERING} ${EASING_ENTERING}`,
                    style({ opacity: 1, transform: 'translateY(0%)' })
                )
            ])
        ]),
        query(':enter', animateChild())
    ])
];
