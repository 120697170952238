import { animate, query, stagger, style, transition, trigger } from '@angular/animations';

import {
    EASING_ENTERING,
    EASING_LEAVING,
    VELOCITY_ENTERING,
    VELOCITY_LEAVING,
    VELOCITY_STAGGER_ENTERING,
    VELOCITY_STAGGER_LEAVING
} from './animation.constants';

export const fadeIn = trigger('fadeIn', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate(`${VELOCITY_ENTERING} ${EASING_ENTERING}`, style({ opacity: 1 }))
    ])
]);

export const fadeOut = trigger('fadeOut', [
    transition(':leave', [
        style({ opacity: 1 }),
        animate(`${VELOCITY_LEAVING} ${EASING_LEAVING}`, style({ opacity: 0 }))
    ])
]);

export const fadeOutSlow = trigger('fadeOut', [
    transition(':leave', [
        style({ opacity: 1 }),
        animate(`480ms ${EASING_LEAVING}`, style({ opacity: 0 }))
    ])
]);

export const fadeInOut = trigger('fadeInOut', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate(`${VELOCITY_ENTERING} ${EASING_ENTERING}`, style({ opacity: 1 }))
    ]),
    transition(':leave', [
        style({ opacity: 1 }),
        animate(`${VELOCITY_LEAVING} ${EASING_LEAVING}`, style({ opacity: 0 }))
    ])
]);

export const slideOutUp = trigger('slideOutUp', [
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
            `${VELOCITY_LEAVING} ${EASING_LEAVING}`,
            style({ opacity: 0, transform: 'translateY(-30%)' })
        )
    ])
]);
export const slideInUp = trigger('slideInUp', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-30%)' }),
        animate(
            `${VELOCITY_LEAVING} ${EASING_LEAVING}`,
            style({ opacity: 1, transform: 'translateY(0)' })
        )
    ])
]);
export const slideOutDown = trigger('slideOutDown', [
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
            `${VELOCITY_LEAVING} ${EASING_LEAVING}`,
            style({ opacity: 0, transform: 'translateY(30%)' })
        )
    ])
]);
export const slideInDown = trigger('slideInDown', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(30%)' }),
        animate(
            `${VELOCITY_ENTERING} ${EASING_ENTERING}`,
            style({ opacity: 1, transform: 'translateY(0)' })
        )
    ])
]);

export const slideInOutUp = trigger('slideInOutUp', [
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
            `${VELOCITY_LEAVING} ${EASING_LEAVING}`,
            style({ opacity: 0, transform: 'translateY(-30%)' })
        )
    ]),
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-30%)' }),
        animate(
            `${VELOCITY_ENTERING} ${EASING_ENTERING}`,
            style({ opacity: 1, transform: 'translateY(0)' })
        )
    ])
]);

export const slideInOutDown = trigger('slideInOutDown', [
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0)' }),
        animate(
            `${VELOCITY_LEAVING} ${EASING_LEAVING}`,
            style({ opacity: 0, transform: 'translateY(30%)' })
        )
    ]),
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(30%)' }),
        animate(
            `${VELOCITY_ENTERING} ${EASING_ENTERING}`,
            style({ opacity: 1, transform: 'translateY(0)' })
        )
    ])
]);

export const slideInOutLeft = trigger('slideInOutLeft', [
    transition(':leave', [
        style({ opacity: 1, transform: 'translateX(0)' }),
        animate(
            `${VELOCITY_LEAVING} ${EASING_LEAVING}`,
            style({ opacity: 0, transform: 'translateX(-30%)' })
        )
    ]),
    transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-30%)' }),
        animate(
            `${VELOCITY_ENTERING} ${EASING_ENTERING}`,
            style({ opacity: 1, transform: 'translateX(0)' })
        )
    ])
]);

export const slideInList = trigger('slideInList', [
    transition('* => *', [
        query(
            ':enter',
            [
                style({ opacity: 0, transform: 'translateY(-30%)' }),
                stagger(40, [
                    animate(
                        `${VELOCITY_STAGGER_ENTERING} ${EASING_ENTERING}`,
                        style({ opacity: 1, transform: 'translateY(0)' })
                    )
                ])
            ],
            { optional: true }
        )
    ])
]);

export const slideInOutList = trigger('slideInOutList', [
    transition('* => *', [
        query(
            ':leave',
            [
                animate(
                    VELOCITY_STAGGER_LEAVING,
                    style({ opacity: 0, transform: 'translateY(-30%)' })
                )
            ],
            { optional: true }
        ),
        query(
            ':enter',
            [
                style({ opacity: 0, transform: 'translateY(-30%)' }),
                stagger(40, [
                    animate(
                        `${VELOCITY_STAGGER_ENTERING} ${EASING_ENTERING}`,
                        style({ opacity: 1, transform: 'translateY(0)' })
                    )
                ])
            ],
            { optional: true }
        )
    ])
]);
